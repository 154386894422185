import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reloadCarList } from "../car-listing/actions";
import { fetchCategoryFilters, updateAllSelectedFilterOptions, updateFilterOptionData, updateSelectedCategories } from "../filters/actions";
import CategoryContentSection from "./component";

const mapStateToProps = ({
    filters: {
        categories,
        loadingCategory,
        categoryError
    },
    cities: {
        selectedCity
    }
}) => ({
    categories,
    loadingCategory,
    categoryError,
    selectedCity
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateFilterOptionDataConnect: updateFilterOptionData,
    fetchCategoryFiltersConnect: fetchCategoryFilters,
    reloadCarListConnect: reloadCarList,
    updateAllSelectedFilterOptionsConnnect: updateAllSelectedFilterOptions,
    updateSelectedCategoriesConnect: updateSelectedCategories
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CategoryContentSection);
