import React from "react";
import styles from "./styles.css";
import HardWorkingImage from "./images/category-1.png";
import BigFamilyImage from "./images/category-2.png";
import HybridImage from "./images/category-3.png";
import ConvertibleImage from "./images/category-4.png";
import SmallFamilyImage from "./images/category-5.png";
import InnerCityImage from "./images/category-6.png";
import DrivingPleasureImage from "./images/category-7.png";
import GreatOutdoorsImage from "./images/category-8.png";
import PropTypes from "prop-types";
import { DEFAULT_CITY } from "../../../constants/app-constants";
import Filters from "../../../utils/filters-v2";
import { useHistory } from "react-router-dom";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
// import LazyImage from "../../shared/lazy-image";

import WebPBigFamilyImage from "./images/webp/category-2.webp";
import WebPHardWorkingImage from "./images/webp/category-1.webp";
import WebPHybridImage from "./images/webp/category-3.webp";
import WebPConvertibleImage from "./images/webp/category-4.webp";
import WebPSmallFamilyImage from "./images/webp/category-5.webp";
import WebPInnerCityImage from "./images/webp/category-6.webp";
import WebPDrivingPleasureImage from "./images/webp/category-7.webp";
import WebPGreatOutdoorsImage from "./images/webp/category-8.webp";

import { useInView } from "react-intersection-observer";
import TrackImpression from "../../shared/track-impression";

const CATEGORY_IMAGES = {
    hardworker: HardWorkingImage,
    bigfamily: BigFamilyImage,
    "hybrid/ecofriendly": HybridImage,
    convertible: ConvertibleImage,
    smallfamily: SmallFamilyImage,
    innercity: InnerCityImage,
    drivingpleasure: DrivingPleasureImage,
    greatoutdoors: GreatOutdoorsImage
};

const CATEGORY_IMAGES_WEBP = {
    hardworker: WebPBigFamilyImage,
    bigfamily: WebPHardWorkingImage,
    "hybrid/ecofriendly": WebPHybridImage,
    convertible: WebPConvertibleImage,
    smallfamily: WebPSmallFamilyImage,
    innercity: WebPInnerCityImage,
    drivingpleasure: WebPDrivingPleasureImage,
    greatoutdoors: WebPGreatOutdoorsImage
};

const CategoryContentSection = ({
    fetchCategoryFiltersConnect,
    updateAllSelectedFilterOptionsConnnect,
    updateSelectedCategoriesConnect,
    categories = [],
    // loadingCategory,
    // categoryError,
    selectedCity,
    reloadCarListConnect

}) => {
    const history = useHistory();
    const [ref, inView] = useInView({ threshold: 0, triggerOnce: true});

    React.useEffect(() => {
        if (!(categories || []).length) {
            fetchCategoryFiltersConnect();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGoToListingPage = (data, clickedCategory) => {
        const { allFilters = [] } = data || {};
        let selectedFilters = [];
        Object.keys(allFilters).map((filter) => {
            (allFilters[filter].options || []).map((option) => {
                let currentSelectedFilter = {
                    filterKey: allFilters[filter].key,
                    optionKey: option.key,
                    isSelected: true,
                    isRangeFilter: allFilters[filter].key === "quotedPrice" || allFilters[filter].key === "odometerReading" || allFilters[filter].key === "year",
                    max: allFilters[filter].min,
                    min: allFilters[filter].max,
                    displayText: option.displayText
                };
                if (option.subFilter && (option.subFilter.options || []).length) {
                    option.subFilter.options.map((subOption) => {
                        currentSelectedFilter = {
                            ...currentSelectedFilter,
                            displayText: subOption.displayText,
                            subOptionKey: subOption.key
                        };
                        selectedFilters = [ ...selectedFilters, currentSelectedFilter];
                    });
                } else {
                    selectedFilters = [ ...selectedFilters, currentSelectedFilter];
                }
            });
        });
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.HOMEPAGE_CATEGORY, {eventLabel: clickedCategory });
        updateAllSelectedFilterOptionsConnnect(selectedFilters);
        updateSelectedCategoriesConnect([clickedCategory]);
        const filters = new Filters({cityCode: selectedCity.code || DEFAULT_CITY.AU.code});
        const {relativeURL} = filters.getListingPayload({selectedFilters, filters: allFilters, categories: [clickedCategory]});
        reloadCarListConnect(true);
        history.push(relativeURL);
    };

    return (
        <TrackImpression event={{name: "lifeStyleCarsViewd"}}>
            <div className={"container"} styleName={"styles.outer"}>
                <p styleName={"styles.heading"}>Find a car that <span>perfectly</span> suits  your lifestyle</p>
                <div styleName={"styles.boxWrapper"}>
                    {
                        categories.map((data) =>
                            (<div styleName={"styles.categoryCard"} key={data.key} onClick={() => handleGoToListingPage(data.filters, data.key)}>
                                {/* <LazyImage styleName={"styles.categoryImage"} src={CATEGORY_IMAGES[data.key]} alt={data.label} /> */}
                                {inView ? <picture>
                                    <source srcSet={CATEGORY_IMAGES_WEBP[data.key]} type="image/webp" />
                                    <source srcSet={CATEGORY_IMAGES[data.key]} type="image/png" />
                                    <img src={CATEGORY_IMAGES[data.key]} alt="Background Banner" />
                                </picture>
                                    :
                                    <div ref={ref}/>
                                }
                                <div styleName={"styles.category"}>
                                    <p>{data.label}</p>
                                </div>
                            </div>)
                        )
                    }
                </div>
            </div>
        </TrackImpression>
    );
};

CategoryContentSection.propTypes = {
    updateAllSelectedFilterOptionsConnnect: PropTypes.func,
    fetchCategoryFiltersConnect: PropTypes.func,
    updateSelectedCategoriesConnect: PropTypes.func,
    reloadCarListConnect: PropTypes.func,
    categories: PropTypes.array,
    loadingCategory: PropTypes.bool,
    categoryError: PropTypes.bool,
    selectedCity: PropTypes.object
};

export default CategoryContentSection;
